

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.custom-link a {
  color: #4c2618;
}

.custom-link h2 {
  color: #cf533b;
}

.custom-link h1 {
  margin: 0;
  color: #dabd9a;
}

.custom-link h3 {
 font-size: 55px;
  color: #ffff;
}

.fon {
  min-height: 90vh;
  width: 100%;
  background-color: #dabd9a;
  background-size: contain!important;
  background-position: right!important;
  background-repeat: no-repeat!important;
  border-image-slice: fill !important;

}

.item:hover {
  background-color: rgba(25, 218, 22, 0.1);
}

.item hover .zalupa {
  color: pink;
}

/*.css-rpy7cs-MuiButtonBase-root-MuiButton-root, .css-1apkmlw-MuiButtonBase-root-MuiButton-root {
  color: #cf533b!important;
  font-weight: bold!important;
}*/

/*.css-6v2v4c {
  color: #cf533b!important;
  font-weight: bold!important;
}*/
/*
.css-142372x-MuiButtonBase-root-MuiButton-root {
  background-color: #cf533b!important;
}*/

/*.css-kivqd3-MuiButtonGroup-root .MuiButtonGroup-firstButton, .css-kivqd3-MuiButtonGroup-root .MuiButtonGroup-middleButton {
  border-color: #4A2015 !important;

}*/
.text-left-start {
  color: #cf533b!important;
  font-weight: bold!important;

}
.css-rpy7cs-MuiButtonBase-root-MuiButton-root:hover, .css-1apkmlw-MuiButtonBase-root-MuiButton-root:hover {
  background-color: rgba(255, 255, 255, 0.45) !important;
}

p {
  text-align: justify;
}

.css-15rfbsb-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  color: #cf533b!important;
}

.css-15rfbsb-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  color: #cf533b!important;
}

.css-1jlr0am.Mui-active, .css-1jlr0am.Mui-active:hover, .css-1jlr0am.Mui-completed{
  color: #cf533b!important;
}

/*
.css-1jlr0am.Mui-completed {
  color: #cf533b!important;
}*/
/*.css-18ltxn-MuiStepConnector-line {
  border-color: #cf533b!important;
}*/

/*.css-978vd7-MuiButtonBase-root-MuiButton-root:hover {
  background-color: rgba(207, 83, 59, 0.41);
}*/

.main-content {
  height: 100vh;
  display: flex;
  align-items: center;
  padding-left: 20px;
}

a {
  color: #de503b;
  text-decoration: none;
}

a:hover {
  background-color: rgba(255, 255, 255, 0.45) !important;
}

.main-content p {
  color: #573517;
  font-size: 18px;
  font-weight: 500;
}

.css-11os7tz {
  color: #cf533b!important
}
